<template>
  <div class="page" style="margin:0">
    <van-skeleton title :row="23" :loading="loading">
      <!-- 顶部搜索 -->
      <div class="classify_top flex-r-s-c">
        <van-search v-model="serValue" class="classify_ser" placeholder="请输入搜索关键词" readonly shape="round"
                    @click="$router.push('/product/searchpro')"
        >
          <!-- <template #right-icon>
            <van-button round color="#0767AB" size="small" style="width:1.49rem;height:0.69rem;margin-top:-0.1rem"
                        @click="$router.push('/product/searchpro')"
            >搜索</van-button>
          </template> -->
          <!-- <van-image width="1.49rem" height="0.6rem" src="https://oss.xiaoyi120.com/shop2.0/public/ser_btn.png" /> -->
        </van-search>
        <!-- <div class="classify_btn" @click="$router.push('/product/searchpro')">
            <van-button round color="#0767AB" size="small">搜索</van-button>
          </div> -->

        <div class="classify_kefu flex-r-c-c" @click="openKeFu">
          <van-icon name="service-o" size="0.5rem" />
          <span>客服</span>
        </div>
      </div>
      <div class="top_classfly_tab">
        <van-tabs v-model="activeKeyTop" @click="onChangeTab">
          <van-tab v-for="(top,index_top) in topList" :key="index_top" :title="top.name" />
        </van-tabs>
      </div>
      <!-- 分类商品 -->
      <div class="classify_pro_list flex-r-s-s">
        <div class="classify_right_tab" :style="{height:bodyHeigt+'px'}">
          <div v-for="(siderFirst,index_siderFirst) in siderFirstList" :key="index_siderFirst"
               :class="firtIndex === index_siderFirst ?'right_tab_first right_tab_first_actived':'right_tab_first' "
          >
            <div :class="firtIndex === index_siderFirst ? 'right_tab_first_name right_tab_first_name_actived':'right_tab_first_name'"
                 @click="firstChange(siderFirst,index_siderFirst)"
            >{{ siderFirst.name }}</div>
            <template v-if="firtIndex === index_siderFirst">
              <div v-for="(siderSecond,index_siderSecond) in siderFirst.siderSecondList" :key="index_siderSecond"
                   :class="secondIndex === index_siderSecond ? 'right_tab_first_second right_tab_first_second_actived':'right_tab_first_second'"
              >
                <div :class="secondIndex === index_siderSecond ? 'right_tab_first_second_name right_tab_first_second_name_actived':'right_tab_first_second_name'"
                     @click="secondChange(siderSecond,index_siderSecond)"
                >{{ siderSecond.name }}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="classify_pro_list_left flex-r-s-c">
          <div class="left_pro_list" :style="{height:bodyHeigt+'px'}">
            <!-- 顶部过滤 -->
            <div class="serTopFilter_box">
              <serTopFilter ref="serTopFilter" :cid="proSearch.proCategoryId" :type="5" @changeFilter="changeFilter"
                            @popupSearch="popupSearch"
              />
            </div>
            <div name="list">
              <MyscrollList ref="myList01" :loading="listLoading" :list="proList" :line-type="1" @myload="proload">
                <template #default="slotProps">
                  <proCard03 :id="slotProps.index" :info="slotProps.item" @addCart="openCart" />
                </template>
              </MyscrollList>
            </div>
          </div>
        </div>
      </div>
    </van-skeleton>
    <!-- Tabber -->
    <Tabber ref="tabber" :index="2" />
    <!-- 全部分类的遮盖 -->
    <div v-show="show" class="left_pro_list_all" @click="show = false" />
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
    <!-- 底部弹框 -->
    <van-action-sheet v-model="maskShow" title="采购数量">
      <div class="mask_content flex-r-s-c">
        <van-image width="3.41rem" height="2.56rem" :src="openPro.proIconUrl" />
        <div class="mask_pro_info flex-c-s-s">
          <div class="pro_price">
            <template>
              ￥<span> {{ openPro.proPrice }} </span>
            </template>
          </div>
          <div class="pro_name van-multi-ellipsis--l2">{{ openPro.proName }}</div>
          <div class="pro_other van-multi-ellipsis--l2">{{ openPro.proBrand }} | {{ openPro.proModel }}
          </div>
        </div>
      </div>
      <div class="pro_num flex-r-sb-c">
        <div class="name">采购数量</div>
        <van-stepper v-model="value" :min="min" :max="max" :step="step" integer disable-input theme="round"
                     button-size="22" @change="onChange"
        />
      </div>
      <div class="pro_num flex-r-sb-c">
        <div class="name">总计</div>
        <div class="total_price">￥ <span style="font-size: 0.53rem;">{{ totalProPrice }}</span></div>
      </div>
      <van-divider />
      <div class="mask_pro_btn flex-r-c-c">
        <div class="right_btn flex-r-s-s">
          <div class="right_btn_cart" @click="addCart">加入购物车</div>
          <div class="right_btn_pay" @click="subPro">立即采购</div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import './index.scss'
import Tabber from '@/components/tabber'
import MyscrollList from '@/components/MyscrollList'
import proCard03 from '@/components/pro_card_03'
import kefuBtn from '@/components/kefu_btn'
import serTopFilter from '@/components/ser_top_filter'
import { shoppingCart_addUnion } from '@/api/cart'
import { productMallCategory_boss_generations, productMallCategory_boss_list } from '@/api/pro'
import { mallProduct_searchMallProduct } from '@/api/home'
export default {
  name: 'ProClassify',
  components: { Tabber, MyscrollList, proCard03, kefuBtn, serTopFilter },
  data() {
    return {
      bodyHeigt: '',
      value1: '',
      loading: true,
      show: false,
      pid: '0',
      topList: [],
      serValue: '',
      activeKey: 0,
      activeKeyTop: 0,
      firtIndex: 0,
      secondIndex: 0,
      siderFirstList: [{ siderSecondList: [] }],
      rightTitle: '',
      showPopover: false,
      // 商品列表
      proSearch: { page: 1, limit: 10, proCategoryId: '' },
      proList: [],
      listLoading: false,
      // 底部弹框
      maskShow: false,
      value: '',
      min: 1,
      max: 9999,
      step: 1,
      totalProPrice: '',
      openPro: {}
    }
  },
  created() {
    console.log('created 进入classify')
    this.getSideData() // 获取侧边分类
    this.$utils.cartNum(this.$store)
    var clientHeight = `${document.documentElement.clientHeight}`
    console.log('clientHeight1', clientHeight) // 1.6  1.4 2 = 7 * 37.5 = 262.5
    this.bodyHeigt = (clientHeight * 1 - 200).toFixed(2)
    console.log('clientHeight2', this.bodyHeigt) // 1.6  1.4 2 = 5 * 37.5 = 187.5
  },
  activated() {
    console.log('keepalive 进入classify')
    if (sessionStorage.getItem('tabberJump')) {
      this.loading = true
      // this.$router.go(0)
      // window.location.reload()
      sessionStorage.removeItem('tabberJump')
      this.getSideData() // 获取侧边分类
      this.$utils.cartNum(this.$store)
      var clientHeight = `${document.documentElement.clientHeight}`
      console.log('clientHeight1', clientHeight) // 1.6  1.4 2 = 7 * 37.5 = 262.5
      this.bodyHeigt = (clientHeight * 1 - 200).toFixed(2)
      console.log('clientHeight2', this.bodyHeigt) // 1.6  1.4 2 = 5 * 37.5 = 187.5
    } else {
      var id = sessionStorage.getItem('classify_pro_id')
      document.getElementById(id).scrollIntoView()
    }
  },
  deactivated() {
    this.$refs.serTopFilter.closePullBox()
    console.log('keepalive 离开classify')
  },
  methods: {
    getSideData() {
      productMallCategory_boss_generations(this.pid).then(res => {
        this.topList = res.data
        this.onChangeTab(0)
      })
    },
    // 重新加载商品更新事件
    reloadProList() {
      this.proList = []
      this.siderFirstList = []
      this.proSearch.page = 1
    },
    // 顶部切换
    onChangeTab(index) {
      this.reloadProList()
      const nextId = this.topList[index].id
      productMallCategory_boss_list({ proCategoryPid: nextId, type: 'top' }).then(res => {
        this.loading = false
        this.siderFirstList = res.data
        this.proSearch.proCategoryId = res.data[0].id
        this.firstChange(res.data[0], 0)
        this.$refs.myList01.load_status_clear()
        this.$refs.serTopFilter.closePullBox()
      })
    },
    // 侧边一级切换
    firstChange(item, index) {
      console.log('closePullBox 1')
      this.firtIndex = index
      productMallCategory_boss_list({ proCategoryPid: item.id, type: 'sider' }).then(res => {
        item.siderSecondList = res.data
        this.secondChange(res.data[0], 0)
        this.$refs.serTopFilter.closePullBox()
      })
    },
    // 侧边二级切换
    secondChange(item, index) {
      console.log('closePullBox 2')
      this.$refs.serTopFilter.closePullBox()
      this.$refs.myList01.load_status_clear()
      this.secondIndex = index
      this.proSearch.proCategoryId = item.id
      this.rightTitle = item.name
      this.proList = []
      this.proSearch.page = 1
      this.loadPro()
      console.log('this.rightTitle', this.rightTitle)
      this.popupSearch({ tagId: '', proBrands: [] })
    },
    // 加载商品
    loadPro(index) {
      mallProduct_searchMallProduct(this.proSearch).then(res => {
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.list)
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.list.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.page = this.proSearch.page + 1
        this.loadPro()
        // 加载状态结束
      }, 50)()
    },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) },
    changeFilter(e) {
      this.proSearch.page = 1
      console.log(e)
      if (e === 1 || e === 41) {
        this.proSearch.orderType = ''
        this.proSearch.orderWay = ''
        this.proSearch.tagId = ''
      }
      if (e === 2) {
        this.issaleNum = !this.issaleNum
        this.proSearch.orderType = 'SALE_NUM'
        this.proSearch.orderWay = this.issaleNum ? 'DESC' : 'ASC'
        this.proSearch.tagId = ''
      }
      if (e === 3) {
        this.isprice = !this.isprice
        this.proSearch.orderType = 'PRICE'
        this.proSearch.orderWay = this.isprice ? 'DESC' : 'ASC'
        this.proSearch.tagId = ''
      }
      if (e === 4) {
        this.$refs.serTopFilter.rightTitle(this.rightTitle)
      }
      if (e === 42) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''
        this.proSearch.tagId = '282'
      }
      if (e === 43) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''
        this.proSearch.tagId = '281'
      }
      if (e === 44) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''
        this.proSearch.tagId = '275'
      }
      this.proList = []
      this.loadPro()
    },
    popupSearch(item) {
      this.$refs.myList01.load_status_clear()
      console.log(item)
      this.proSearch.page = 1
      this.proSearch.tagId = item.tagId
      this.proSearch.proBrands = item.proBrands
      this.proList = []
      this.loadPro()
    },
    openCart(item) {
      this.openPro = item
      this.maskShow = true
      this.value = 1
      if (item.quantity === null || item.quantity === undefined) {
        this.min = item.minQuantity
        this.step = item.minQuantity
        this.totalProPrice = (parseInt(item.minQuantity) * item.proPrice * 1).toFixed(2)
      } else {
        this.min = item.fashProNum
        this.totalProPrice = (parseInt(item.fashProNum) * item.proPrice * 1).toFixed(2)
      }
    },
    onChange(value) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.value = value
        this.totalProPrice = (parseInt(value) * this.openPro.proPrice * 1).toFixed(2)
      }, 500)
    },
    addCart() {
      const parms = { productId: this.openPro.productId, proNumber: this.value }
      console.log(parms)
      shoppingCart_addUnion(parms).then(res => {
        this.$toast({ message: '加入成功！', duration: 1 * 1000 })
        this.$utils.cartNum(this.$store)
        this.maskShow = false
      })
    },
    subPro() {
      let url = ''
      url = `/order/subOrder?item=${this.openPro.productId}-${this.value}-1000&type=2`
      this.$router.push(url)
    }
  }
}
</script>

<style>
</style>
